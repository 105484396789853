@import 'less/mixins.less';
@import 'less/variables.less';
@import 'less/reset.less';
@import 'less/slick.less';
@import 'less/slick-theme.less';
@import 'icomoon.css';
@import 'jquery.fancybox.min.css';

body {
  min-height: 100vh;
}

header {
  background-color: #242b33;

  .phones-block {
    z-index: 0;
    * {
      color: #fff;
      font-size: 18px;
      display: block;
    }
    a {
      font-family: @OpenSans;
      &:hover {
        text-decoration: underline;
      }
    }
    & > div {
      margin: 5px 0 -35px auto;
      position: relative;
      max-width: 210px;
      padding-left: 54px;
    }
    .fa-phone {
      width: 48px;
      height: 48px;
      display: inline-block;
      .border-radius(100%);
      border: 2px solid @green;
      position: absolute;
      //bottom: -18px;
      left: 0;
      margin-right: 10px;
      .transform(scaleX(-1));
      &:before {
        color: @green;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 22px;
        height: 23px;
        font-size: 22px;
      }
    }
  }

  .logo-block {
    padding-bottom: 10px;
    z-index: 0;
    img {
      max-width: 82%;
      height: auto;
    }
  }

  .menu-block {
    .justify-content();
    width: calc(~'83.33333% - 232px');
    width: -webkit-calc(~'83.33333% - 232px');
    width: -moz-calc(~'83.33333% - 232px');
    * {
      color: #fff;
    }
    & > .row {
      .justify-content(flex-end, end);
    }
    .top-menu {
      .toggle-menu {
        display: none;
        width: 50px;
        height: 40px;
        .justify-content();
        margin: 10px auto;
        @media all and (max-width: 991px) {
          .display-flex();
          height: auto; .align-items(center, center);
          .flex-direction(column, normal, vertical);
          cursor: pointer;
          width: 100px;
        }
        .toggle-bar {
          width: 44px;
          height: 5px;
          background-color: #fff;
          margin: 3px auto;
          .border-radius(20px);
        }
      }
      .nav-menu {
        @media all and (min-width: 992px) {
          .display-flex() !important;
          .justify-content(space-between, justify);
        }
        .sub-menu, .dropdown-menu {
          //text-transform: uppercase;
          text-align: left;
          animation-fill-mode: both;
          @media all and (min-width: 992px) {
            display: none;
            background-color: @green;
            animation-name: fadeOutDownSmall;
            position: absolute;
            top: 4em;
            left: 50%;
            margin-left: -6em;
            animation-duration: .5s;
            min-width: 13em;
            padding: 1.4em 0;
            line-height: 1.5em;
            z-index: 1;
          }
          @media all and (max-width: 991px) {
            * {
            }
          }
          li {
            padding: 0.6em 1.6667em;
            &:hover a {
              color: #13162b;
            }
          }
        }
        //&> li.dropdown:nth-of-type(2) > .dropdown-menu {
        //  .column-count(3);
        //}
        .has-sub-menu, .dropdown {
          &:hover {
            & > .dropdown-menu {
              display: block;
              animation-name: fadeInUpSmall;
            }
          }

        }
        & > li {
          position: relative;
          z-index: 2;
          padding: 20px 0;
          .transition(all .2s);
          &.active, &:hover {
            & > a {
              &:after {
                width: 100%;
              }
            }
          }
          &, a {
            color: #fff;
            font-size: 15px;
            letter-spacing: .5px;
            text-decoration: none;
            font-family: @OpenSans;
          }
          a {
            display: inline-block;
            width: 100%;
            position: relative;
            .transition(all .2s);
            &:after {
              position: absolute;
              width: 0;
              height: 2px;
              background-color: @green;
              content: '';
              left: 0;
              right: 0;
              bottom: -4px;
              margin: auto;
              .transition(all .2s);
            }
          }
        }
        @media all and (max-width: 991px) {
          display: none;
          position: absolute;
          top: 98px;
          left: 0;
          right: 0;
          width: 100%;
          padding: 20px 0;
          background-color: #fff;
          border: 1px solid #ececec;
          text-align: center;
          .box-shadow(0 4px 4px rgba(0, 0, 0, .3));
          z-index: 2;
          li {
            &:not(.order-block) {
              padding-top: 0;
              padding-bottom: 0;
              color: #000;
              a {
                color: #000;
                padding: 1.6vh 10px;
              }
              &.active, &:hover {
                & > a {
                  color: #fff;
                  //border-color: #3fb9be;
                  background-color: @green;
                }
              }
            }
            &:after {
              display: none;
            }
            .sub-menu {
              display: block;
              position: relative;
            }
          }
        }
      }
    }
  }
  .order-block {
    .justify-content();
    .align-items();
    width: 232px;
    .btn {
      margin: 0 0 0 auto;
      color: #fff;
      font-family: @OpenSans;
      text-transform: none;
      font-size: 18px;
    }
  }
}

footer {
  position: relative;
  //bottom: 0;
  //left: 0;
  //right: 0;
  z-index: 1;
  * {
    color: #fff;
  }
  .footer-top {
    background-color: #242b33;
    padding: 34px 0 18px;
    .block-title {
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    .logo-block {
      margin-bottom: 24px;
      img {
        max-width: 90px;
      }
    }

    .copyright {
      margin-bottom: 25px;
      width: 94%;
      &, * {
        font-size: 14px;
      }
      a {
        text-transform: uppercase;
      }
    }

    .footer-contacts-block {
      p {
        margin-bottom: 15px;
        &, a {
          font-size: 14px;
          line-height: 20px;
          display: block;
        }
      }
    }
    .services-menu {
      li {
        margin-bottom: 6px;
        a {
          line-height: normal;
        }
      }
    }
    .footer-menu {
      margin-left: 3vw;
      li {
        margin-bottom: 10px;
        a {
          //text-transform: uppercase;
        }
      }
    }
  }
  .footer-bottom {
    background-color: #000;
    padding: 19px 0;
    .social-btns {
      a {
        display: inline-block;
        width: 28px;
        height: 28px;
        margin-right: 14px;
        position: relative;
        z-index: 1;
        .border-radius(100%);
        overflow: hidden;
        background-color: @green;
        color: #fff;
        .transition(all .1s);
        &:hover {
          .transform(scale(1.1, 1.1));
        }
        i {
          text-align: center;
          font-size: 18px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          height: 18px;
          z-index: 0;
        }
      }
    }
    .developer {
      a {
        font-family: @OpenSans;
      }
      img {
        max-width: 89px;
        margin-bottom: -13px;
        margin-left: 5px;
        -webkit-filter: grayscale(100%) brightness(500%);
        -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Grayscale=100, Brightness=500);
        filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
        filter: grayscale(100%) brightness(500%);
        &:hover {
          -webkit-filter: grayscale(0) brightness(100%);
          -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Grayscale=0, Brightness=100);
          filter: grayscale(0) brightness(100%);
        }
      }
    }
  }
}

.btn-block {
  text-align: center;
  padding-top: 40px;
}

.btn {
  padding: 7px 1px;
  border: 2px solid @green;
  font-size: 15px;
  line-height: 25px;
  //line-height: normal;
  color: #000;
  background-color: transparent;
  text-transform: uppercase;
  font-family: @HelveticaNeue;
  text-align: center;
  text-decoration: none;
  width: 100%;
  display: inline-block;
  max-width: 214px;
  height: 42px;
  cursor: pointer;
  .border-radius(20px);
  .transition(all .2s);
  &:hover {
    background-color: @green;
    text-decoration: none;
  }
}

.btn-dark {
  background-color: #242b33;
  color: #fff;
  &:hover {
    background-color: #fff;
    color: @green;
  }
}

.form-group.has-error input {
  border-color: red !important;
}

.help-block {
  color: red !important;
  position: absolute;
  padding-top: 3px;
}

/* content */
main {
  section {
    padding: 82px 0;
    position: relative;
    overflow: hidden;
    &.gray-bg, .gray-bg {
      background-color: @wildSand;
    }
    .desc-block {
      * {
        font-size: 15px;
        line-height: 23px;
        position: relative;
      }
      h1 {
        font-weight: 800;
        font-size: 19px;
        line-height: 20px;
        margin-bottom: 25px;
        margin-top: 40px;
      }
      h2 {
        font-size: 17px;
        margin-bottom: 15px;
      }
      .block-subtitle, h3 {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 25px;
        margin-top: 40px;
        font-weight: 400;
        &.icon {
          margin-bottom: 35px;
          padding-left: 90px;
          img {
            float: left;
            width: 76px !important;
            margin: -30px 10px -30px -90px;
          }
        }
      }
      img {
        width: 50% !important;
        max-width: 400px;
        height: auto !important;

        &.rightfloat {
          margin: 0 0 24px 24px;
        }
        &.leftfloat {
          margin: 0 24px 24px 0;
        }
      }
      p, ul {
        margin-bottom: 25px;
      }
      ul {
        position: relative;
        &.columns-2 {
          column-count: 2;
        }
        li {
          padding-left: 35px;
          margin-bottom: 8px;
          line-height: 18px;
          &:before {
            content: '';
            width: 4px;
            height: 4px;
            .border-radius(100%);
            background-color: #000;
            position: absolute;
            top: 10px;
            left: 10px;
          }
        }
      }
      a {
        color: @green;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .section-title {
    font-size: 30px;
    line-height: 33px;
    text-transform: uppercase;
    font-weight: 400;
    position: relative;
    margin-bottom: 90px;
    text-align: center;
    font-family: @Roboto;
    word-wrap: break-word;
    &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      width: 73px;
      height: 3px;
      background-color: @green;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .rent-section {
    background-image: url('../images/bgr-dots.png');
    background-repeat: no-repeat;
    background-position: 36vw 100%;
    background-size: auto auto;
    padding-bottom: 0;
    .section-title {
      text-align: center;
      font-size: 40px;
      line-height: 33px;
      text-transform: uppercase;
      margin-bottom: 0;
      font-weight: 800;
      &:after {
        display: none;
      }
    }
    .rent-block {
      .rent-list {
        width: 34%;
        padding: 70px 0 40px;
        li {
          height: 64px;
          cursor: pointer;
          position: relative;
          .display-flex();
          .align-items(center);
          .justify-content(flex-end, end);
          a {
            font-size: 16px;
            .display-flex();
            .align-items();
            height: 64px;
            width: 100%;
            max-width: 278px;
            position: relative;
            .transition(all .2s);
            span {
              display: inline-block;
              width: 60px;
              text-align: center;
            }
            svg, img {
              margin-bottom: -11px;
              margin-top: -5px;
              margin-right: 10px;
              position: relative;
              width: 40px;
              * {
                //fill: #000;
              }
            }
            [class^='icon-ico'] {
              color: #000;
            }
          }
          &:hover, &.active {
            background-color: #fff;
            &:after {
              content: '';
              border-left: 17px solid #fff;
              border-top: 31px solid transparent;
              border-bottom: 31px solid transparent;
              width: 0;
              height: 0;
              position: absolute;
              right: -16px;
              top: 0;
              bottom: 0;
              margin: auto;
            }
            a {
              font-size: 20px;
              font-weight: 400;
              &:hover {
                text-decoration: none;
              }
              [class^='icon-ico'] {
                color: @green;
              }
              svg, img {
                * {
                  fill: @green;
                }
              }
            }
          }
        }
      }
      .rent-content {
        width: 66%;
        padding-left: 6vw;
        .rent-item {
          display: none;
          width: 100%;
          background-repeat: no-repeat;
          background-position: 21vw 0;
          background-size: contain;
          padding: 70px 0 122px;
          height: calc(~'100% + 1px');
          height: -webkit-calc(~'100% + 1px');
          height: -moz-calc(~'100% + 1px');
          & > * {
            width: 100%;
            max-width: 334px;
          }
          .block-title {
            font-size: 30px;
            line-height: 33px;
            text-transform: uppercase;
            font-weight: 400;
            position: relative;
            margin-bottom: 90px;
            font-family: @Roboto;
            &:after {
              content: '';
              position: absolute;
              bottom: -20px;
              width: 73px;
              height: 3px;
              background-color: @green;
              left: 0;
            }
          }
          p {
            font-size: 15px;
            line-height: 23px;
            font-weight: 400;
            margin-bottom: 60px;
          }
        }
      }
    }
  }
  .autopark-section {
    .desc-block {
      .block-subtitle {
        margin: -15px auto 40px;
        text-align: center;
        span {
          font-family: @HelveticaNeueBold;
          font-size: 18px;
        }
      }
    }
    .autopark-block {
      margin-bottom: 18px;
      .autopark-list {
        text-align: center;
        margin-bottom: 40px;
        li {
          display: inline-block;
          cursor: pointer;
          margin-bottom: 20px;
          a {
            background-color: @wildSand;
            display: block;
            padding: 12px 30px;
            font-size: 15px;
            text-transform: uppercase;
            font-family: @HelveticaNeue;
            .border-radius(4px);
            font-weight: 400;
            .transition(all .2s);
            position: relative;
            &:after {
              content: '';
              position: absolute;
              bottom: -8px;
              left: 0;
              right: 0;
              margin: auto;
              border-top: 10px solid @green;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              width: 0;
              height: 0;
              .transition(all .3s);
              opacity: 0;
            }
          }
          &:hover, &.active {
            a {
              text-decoration: none;
              background-color: @green;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .autopark-content {
      .row {
        .autopark-item {
          display: none;
        }
      }
    }
    .autopark-content, .autopark-grid {
      .row {
        width: calc(~'100% + 30px');
        width: -webkit-calc(~'100% + 30px');
        width: -moz-calc(~'100% + 30px');
        margin-left: -15px;
        margin-right: -15px;
      }
      .autopark-item {
        & > div:not(.container) {
          padding: 0 15px 68px;
        }
        .img-block {
          position: relative;
          overflow: hidden;
          width: 100%;
          display: block;
          margin: auto;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background-color: rgba(36, 43, 51, 0.7);
            z-index: 1;
            opacity: 0;
            .transition(all .2s);
          }
          &:after {
            content: '';
            display: block;
            padding-top: 70%;
          }
          img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            height: auto;
          }
          .hidden-content {
            opacity: 0;
            .transition(all .2s);
            z-index: 1;
            position: absolute;
            margin: auto;
            text-align: center;
            right: 0;
            left: 0;
            top: 30%;
            * {
              color: #fff;
            }
            .block-title {
              font-size: 18px;
              font-weight: 400;
              margin-bottom: 20px;
              text-align: center;
              font-family: @HelveticaNeueBold;
            }
            ul {
              display: inline-block;
              width: auto;
              margin: auto;
              text-align: left;
              li:not(:last-of-type) {
                margin-bottom: 8px;
              }
            }
          }
          &:hover {
            &:before {
              opacity: 1;
            }
            .hidden-content {
              opacity: 1;
            }
          }
        }
        .order-btn, .price-block {
          padding: 15px 15px 0;
        }
        .order-btn {
          width: 162px;
          .btn {
            line-height: 26px;
          }
        }
        .price-block {
          width: calc(~'100% - 162px');
          width: -webkit-calc(~'100% - 162px');
          width: -moz-calc(~'100% - 162px');
          font-size: 15px;
          font-family: @HelveticaNeue;
          span {
            color: @green;
            font-size: 24px;
            font-weight: 700;
            font-family: @HelveticaNeueBold;
            padding-top: 12px;
            display: inline-block;
          }
        }
      }
    }
  }

  .autopark-page-section {
    padding-bottom: 0;
    .autopark-block {
      margin-bottom: 0;
      .autopark-grid {
        margin-bottom: 60px;
        .autopark-grid-item {
          margin-bottom: 4px;
          .h2 {
            font-size: 26px;
            margin-bottom: 20px;
            display: block;
          }
          .row {
            width: calc(~'100% + 30px');
            width: -webkit-calc(~'100% + 30px');
            width: -moz-calc(~'100% + 30px');
            margin-left: -15px;
            margin-right: -15px;

            [class*='col-'] {
              padding: 0 15px 30px;
            }
          }
          .gallery-block {
            margin-bottom: 30px;
            .gallery-for {
              margin-bottom: 7px;
              .gallery-item {
                position: relative;
                overflow: hidden;
                &:after {
                  content: '';
                  display: block;
                  padding-top: 70%;
                }
                img {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  margin: auto;
                  min-width: 100%;
                  min-height: 100%;
                  width: auto;
                  height: auto;
                  max-width: 144%;
                }
              }
              .slick-arrow {
                z-index: 1;
                height: 55px;
                width: 33px;
                &:before {
                  display: none;
                }
                &.slick-prev {
                  left: 20px;
                  img {
                    .transform(scaleX(-1));
                  }
                }
                &.slick-next {
                  right: 20px;
                }
              }
            }
            .gallery-nav {
              .gallery-item {
                margin: 0 6px 6px 0;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                &:after {
                  content: '';
                  display: block;
                  padding-top: 100%;
                }
                img {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  margin: auto;
                  min-width: 100%;
                  min-height: 100%;
                  width: auto;
                  height: auto;
                  max-width: 200%;
                }
              }
            }
          }
          .table-block {
            margin: 0 0 30px;
            table {
              td {
                padding: 7px 10px;
                min-height: 25px;
                text-align: left;
                &:first-of-type {
                  width: 36%;
                }
                &:last-of-type {
                  width: 64%;
                }
              }
            }
          }
          .btn {
            max-width: 132px;
          }
        }
      }
      .desc-block {
        padding: 120px 0 73px !important;
        .block-subtitle {
          text-align: left;
        }
        .table-block {
          margin-bottom: 35px;
        }
      }
    }
  }

  .calc-section {
    padding-bottom: 70px;
    margin-bottom: 40px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 70px;
      left: 0;
      right: 0;
      background-color: @wildSand;
      z-index: -1;
    }
    .desc-block {
      width: 90%;
    }
    .calc-block {
      text-align: center;
      padding: 47px 30px;
      background-color: @green;
      margin-bottom: -70px;
      .block-title {
        font-size: 20px;
        font-family: @HelveticaNeueBold;
        margin-bottom: 30px;
      }
      .calc-form {
        max-width: 288px;
        margin: auto;
        .form-group {
          margin-bottom: 30px;
        }
        label {
          display: block;
          font-size: 15px;
          line-height: 23px;
          text-align: left;
        }
        select {
          height: 30px;
          border: 1px solid #fff;
          .border-radius(4px);
          width: 100%;
          padding: 0 7px;
          font-size: 15px;
          position: relative;
          background: #fff url('../images/ico_arrow.png') no-repeat 98% 50%;
        }
        .submit-group {
          margin-top: 60px;
          .btn {
            max-width: 259px;
          }
        }
      }
    }
  }

  .advantages-section {
    .section-title {
      margin-bottom: 35px;
    }
    .desc-block {
      text-align: center;
      margin-bottom: 75px;
    }
    .advantages-grid {
      .img-block {
        width: 76px;
      }
      .advantages-item {
        max-width: 354px;
        margin: 0 auto 30px;
      }
      .content-block {
        width: calc(~'100% - 76px');
        width: -webkit-calc(~'100% - 76px');
        width: -moz-calc(~'100% - 76px');
        padding: 0 25px 25px;
        .block-title {
          font-size: 18px;
          line-height: 20px;
          margin-bottom: 20px;
        }
        ul {
          li {
            padding-left: 35px;
            margin-bottom: 10px;
            position: relative;
            font-size: 15px;
            &:before {
              content: '';
              width: 4px;
              height: 4px;
              .border-radius(100%);
              background-color: #000;
              position: absolute;
              top: 7px;
              left: 10px;
            }
          }
        }
      }
    }
  }

  .order-section {
    background-color: #242b33;
    * {
      color: #fff;
    }
    .row {
      width: calc(~'100% + 68px');
      width: -webkit-calc(~'100% + 68px');
      width: -moz-calc(~'100% + 68px');
      margin-left: -34px;
      margin-right: -34px;
    }
    .form-group, .submit-group {
      padding: 0 34px 34px;
    }
    .form-group {
      width: calc(~'50% - 126px');
      width: -webkit-calc(~'50% - 126px');
      width: -moz-calc(~'50% - 126px');
      position: relative;
      label {
        position: absolute;
        top: -6px;
        left: 34px;
        font-size: 14px;
        opacity: .3;
        .transition(all .2s);
      }
      input:not([type=submit]) {
        width: 100%;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid @wildSand;
        height: 42px;
        .transition(all .2s);
        font-size: 18px;
        font-family: @OpenSans;
      }
      input:focus {
        border-bottom: 2px solid @wildSand;
        & + label {
          top: -18px;
          font-size: 12px;
        }
      }
      &.focused {
        input {
          border-bottom: 2px solid @wildSand;
        }
        label {
          top: -18px;
          font-size: 12px;
        }
      }
    }
    .submit-group {
      text-align: center;
      width: 252px;
      .btn {
        margin: auto;
      }
    }
  }

  .reviews-section {
    padding-top: 138px;
    padding-bottom: 70px;
    margin-bottom: 40px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 70px;
      left: 0;
      right: 0;
      background-color: @wildSand;
      z-index: -1;
    }
    .desc-block {
      width: 90%;
    }
    .section-title {
      text-align: left;
      &:after {
        right: auto;
      }
    }
    .reviews-slider-arrows {
      margin: 20px 0;
      li {
        display: inline-block;
        a {
          display: block;
          width: 40px;
          height: 40px;
          border: 2px solid @green;
          .border-radius(100%);
          cursor: pointer;
          .transition(all .2s);
          position: relative;
          background-color: transparent;
          &.prevSlide img {
            .transform(scaleX(-1));
          }
          &:hover {
            background-color: @green;
          }
          img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }
    }

    .reviews-block {
      text-align: center;
      padding: 101px 46px 58px;
      margin-bottom: -70px;
      background: @green url('../images/invalid-name.png') no-repeat 50% 36px;
      .review-title {
        font-size: 20px;
        font-family: @HelveticaNeueBold;
        margin-bottom: 36px;
      }
      .review-content {
        &, * {
          font-size: 15px;
          line-height: 23px;
          position: relative;
        }
      }
    }
  }

  .reviews-page-section {
    .reviews-grid {
      width: calc(~'100% + 30px');
      width: -webkit-calc(~'100% + 30px');
      width: -moz-calc(~'100% + 30px');
      margin-left: -15px;
      margin-right: -15px;

      [class*='col-'] {
        margin-bottom: 30px;
        padding: 0 15px 34px;
        &:not(:last-of-type):not(:nth-last-of-type(2)) {
          border-bottom: 1px solid @wildSand;
        }
      }
      .reviews-item {
        background: url('../images/quote.png') no-repeat 0 0;
        padding-left: 5vw;
        padding-top: 34px;

        .review-title {
          font-size: 18px;
          line-height: 20px;
          margin-bottom: 20px;
          font-family: @HelveticaNeueBold;
          font-weight: 400;
        }
        .review-content {
          &, * {
            font-size: 15px;
            line-height: 23px;
          }
        }
      }
    }
  }

  .blog-section {
    .blog-grid {
      width: calc(~'100% + 28px');
      width: -webkit-calc(~'100% + 28px');
      width: -moz-calc(~'100% + 28px');
      margin-left: -14px;
      margin-right: -14px;
      .blog-item {
        padding: 0 14px 28px;
        text-decoration: none;
        &:hover {
          text-decoration: none;
          .img-block img {
            .transform(scale(1.1, 1.1));
          }
        }
        .img-block {
          position: relative;
          display: block;
          overflow: hidden;
          margin-bottom: -74px;
          &:after {
            content: '';
            display: block;
            padding-top: 66%;
          }
          img {
            position: absolute;
            z-index: 0;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: auto;
            height: auto;
            margin: auto;
            min-width: 100%;
            min-height: 100%;
            .transition(all .2s);
          }
        }
        .content-block {
          background-color: #fff;
          margin: auto;
          position: relative;
          width: calc(~'100% - 60px');
          width: -webkit-calc(~'100% - 60px');
          width: -moz-calc(~'100% - 60px');
          .block-title {
            width: 100%;
            max-width: 354px;
            min-height: 74px;
            .display-flex();
            .align-items();
            padding: 14px 42px;
            background-color: @green;
            color: #fff;
            text-transform: uppercase;
            font-size: 18px;
            line-height: 23px;
            font-family: @HelveticaNeueBold;
          }
          .desc-block {
            padding: 20px 46px 60px;
            font-size: 15px;
            line-height: 23px;
            position: relative;
          }
        }
      }
    }
  }

  .blog-page-section {
    .blog-grid {
      width: calc(~'100% + 20px');
      width: -webkit-calc(~'100% + 20px');
      width: -moz-calc(~'100% + 20px');
      margin-left: -10px;
      margin-right: -10px;
      & > [class*='col-'] {
        padding: 0 10px;
      }
      .main-content {
        .blog-list {
          .blog-item {
            padding-bottom: 30px;
            margin-bottom: 40px;
            position: relative;
            .flex-wrap(nowrap);
            border-bottom: 2px solid @wildSand;
            &:hover img {
              .transform(scale(1.1, 1.1));
            }
            .content-block, .img-block {
              width: 100%;
            }
            .img-block {
              max-width: 417px;
              width: 114%;
              position: relative;
              overflow: hidden;
              margin-left: 17px;
              &:after {
                content: '';
                display: block;
                padding-top: 62%;
              }
              img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: auto;
                height: auto;
                min-width: 100%;
                min-height: 100%;
                max-width: 200%;
                .transition(all .2s);
              }
            }
            .content-block {
              padding-bottom: 15px;
              .block-title {
                font-size: 18px;
                margin-bottom: 30px;
                display: block;
              }
              p {
                font-size: 15px;
                line-height: 23px;
                margin-bottom: 30px;
              }
              .btn {
                text-transform: none;
              }
            }
          }
        }
      }
      .sidebar {
        min-height: calc(~'100% - 20px');
        min-height: -webkit-calc(~'100% - 20px');
        min-height: -moz-calc(~'100% - 20px');
        background-color: @wildSand;
        padding: 52px 2.6vw;

        .widget-block {
          .block-title {
            font-size: 18px;
            line-height: 37px;
            margin-bottom: 20px;
            text-transform: uppercase;
          }
          ul {
            margin-left: 22px;
            li {
              padding-left: 8px;
              position: relative;
              span, a {
                font-size: 16px;
                line-height: 22px;
                display: block;
                margin-bottom: 20px;
              }
            }
            &.categories-list {
              list-style-type: disc;
              & > li {
                & > ul {
                  & > li {
                    &:before {
                      content: '-';
                      position: absolute;
                      left: -12px;
                      top: 2px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .single-post-section {
    padding-top: 130px !important;
  }

  .services-section {
    .services-grid {
      width: calc(~'100% + 30px');
      width: -webkit-calc(~'100% + 30px');
      width: -moz-calc(~'100% + 30px');
      margin-left: -15px;
      margin-right: -15px;
      & > div {
        padding: 0 15px;
      }
    }
  }
  .transfer-section {
    padding-top: 0;
    .transfer-list {
      background-color: @green;
      padding: 30px 0;
      .section-title {
        color: #fff;
        &:after {
          background-color: #fff;
        }
      }
      ul {
        text-align: center;
        .display-flex();
        li {
          width: 100%;
          text-align: center;
          cursor: pointer;
          margin-bottom: 25px;
          a {
            color: #fff;
            font-size: 18px;
            &:hover {
              text-decoration: none;
            }
          }
          span {
            height: 80px;
            max-width: 90px;
            margin: 0 auto 8px;
            display: block;
            clear: both;
          }
          img, svg {
            margin: auto;
            width: auto;
            max-width: 90px;
            height: 80px;
            * {
              fill: #fff;
            }
          }
          &:hover, &.active {
            a {
              color: #242b33;
            }
            img, svg * {
              fill: #242b33;
            }
          }
        }
      }
    }
    .transfer-item {
      display: none;
      padding-top: 53px;
    }
  }

  .table-block {
    margin: 40px auto 20px;
    overflow: auto;
    table {
      margin: 0;
      padding: 0;
      border: 0;
      width: 100%;
      th, td {
        padding: 9px 6px;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        vertical-align: middle;
        .transition(all .2s);
        &:first-of-type {
          width: 50px;
          border-left: 4px solid transparent;
        }
        &:nth-of-type(2) {
          width: 32.3%;
        }
        &:not(:first-of-type) {
          padding-right: 4vw;
        }
      }
      thead {
        tr {
          background-color: @green;
          th, td {
            color: #fff;
          }
        }
      }
      tbody {
        tr {
          .transition(all .2s);
          &:nth-child(odd) td {
            background-color: rgba(198, 203, 212, .24);
          }
          &:nth-child(even) td {
            background-color: #fff;
          }
          &:hover {
            td {
              background-color: #e2e3e7;
              &:first-of-type {
                border-left: 4px solid @green;
              }
            }
          }
          th, td {
            padding: 14px 6px;
          }
          th {
            background-color: rgba(0, 188, 101, 0.15);
            font-family: @HelveticaNeueBold;
            font-weight: 400;
            text-align: left;
            padding: 6px;
          }
          td {
            &:first-of-type {
              padding-left: 16px;
            }
            &:nth-of-type(3) {
              text-align: center;
            }
            &:nth-last-of-type(2), &:last-of-type {
              text-align: right;
            }
            &:last-of-type {
              padding-right: 13px;
            }
          }
        }
      }
    }
  }

  .price-section {
    .desc-block {
      text-align: center;
      margin-top: -25px;
    }
    .table-block {
      margin-top: 60px;
    }
  }

  .contacts-section {
    padding-top: 109px !important;

    [class*='col-'] {
      padding-bottom: 40px;
    }
    .section-title {
      text-align: left;
      &:after {
        right: auto;
      }
    }
    .contacts-block {
      max-width: 390px;
      .contacts-item {
        margin-bottom: 25px;
        * {
          font-size: 15px;
          line-height: 23px;
        }
      }
    }
    .feedback-block {
      .form-group {
        position: relative;
        margin-bottom: 32px;
        label {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 14px;
          color: #8c8e90;
          .transition(all .2s);
        }
        input:not([type=submit]), textarea {
          width: 100%;
          background-color: transparent;
          border: 0;
          border-bottom: 1px solid #242b33;
          height: 42px;
          .transition(all .2s);
          font-size: 18px;
          font-family: @OpenSans;
          resize: none;
        }
        input:focus, textarea:focus {
          border-bottom: 2px solid #242b33;
          & + label {
            top: -18px;
            font-size: 12px;
          }
        }
        &.focused {
          input {
            border-bottom: 2px solid #242b33;
          }
          label {
            top: -18px;
            font-size: 12px;
          }
        }
      }
      .submit-group {
        .btn {
          max-width: 183px;
          margin-top: 20px;
        }
      }
    }
  }

  .map-section {
    padding: 0;
  }

  .breadcrumbs-section {
    position: absolute;
    z-index: 1;
    padding: 24px 0;
    left: 0;
    right: 0;
    margin: auto;
    & + section {
      padding-top: 73px;
    }
    ul {
      li {
        position: relative;
        padding-right: 24px;
        display: inline-block;
        &:not(:last-of-type):after {
          content: '>';
          position: absolute;
          right: 5px;
          font-weight: 600;
        }
        span {
          color: #9b9b9b;
        }
      }
    }
  }

  .pagination-block {
    margin: 45px auto 0;
    ul {
      .justify-content();
      li {
        padding: 0 11px 20px;
        text-align: center;
        a, span {
          width: 48px;
          height: 48px;
          background-color: @wildSand;
          display: block;
          font-size: 16px;
          line-height: 3;
          .transition(all .4s);
        }
        &:hover, &.active {
          a, span {
            background-color: @green;
            color: #fff;
            text-decoration: none;
          }
        }
        &.disabled {
          display: none;
        }
      }
    }
  }
  .error-section {
    min-height: calc(~'100% - 1px');
    min-height: -webkit-calc(~'100% - 1px');
    min-height: -moz-calc(~'100% - 1px');
    .display-flex();
    padding: 7vw 0;

    .row {
      .align-items();
    }
    .img-block {
      max-width: 84%;
    }
    .block-title {
      font-size: 54px;
      line-height: 65px;
      letter-spacing: 2.3px;
      margin-bottom: 20px;
    }
    p {
      color: @green;
      font-size: 14px;
      line-height: 27px;
      margin-bottom: 70px;
    }
    .btn {
      max-width: 160px;
    }
  }

  .partners-grid {
    margin-top: 32px;
    .partners-item {
      margin-bottom: 42px;
      @media all and (min-width: 768px) {
        .flex-wrap(nowrap);
      }
      .img-block {
        min-width: 460px;
        max-width: 460px;
        margin-right: 40px;
        margin-bottom: 30px;
        @media all and (max-width: 991px) {
          min-width: 268px;
          max-width: 268px;
        }
        @media all and (max-width: 767px) {
          width: 100%;
          min-width: 0;
          max-width: 100%;
          margin-right: 0;
        }
        img {
          width: 100%;
        }
      }
      .content-block {
        width: 100%;
        margin-bottom: 30px;
        .block-title {
          font-size: 24px;
          margin-bottom: 15px;
          font-family: @HelveticaNeueBold;
        }
        .desc-block {
          margin-bottom: 20px;
          p {margin-bottom: 15px; line-height: 21px;}
        }
        .site-block {
          background: url('../images/globe.png') no-repeat 0 50%;
          background-size: 20px 20px;
          padding: 4px 0 4px 34px;
          margin-bottom: 14px;
          &, * {color: @green; font-size: 15px;}
        }
        .phone-block {
          background: url('../images/phone.png') no-repeat 0 50%;
          background-size: 20px 20px;
          padding: 4px 0 4px 34px;
          &, * {font-size: 20px;}
        }
      }
    }
  }
}

/* range */
.rangeThumb {
  width: 20px;
  height: 20px;
  margin: -6px 0 0;
  .border-radius(50%);
  background: #fff;
  cursor: pointer;
  border: 0 !important;
  .box-shadow(0 1px 1px 2px rgba(140, 140, 140, .1));
}

.rangeTrack {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #fff;
  .border-radius(100px);
}

.range {
  position: relative;
  width: 100%;
  height: 8px;
  .border-radius(100px);
  margin-top: 15px;
}

.range input {
  width: 100%;
  position: absolute;
  top: 3px;
  height: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  .appearance();

  // Thumb
  &::-webkit-slider-thumb {
    .appearance();
    .rangeThumb;
  }
  &::-moz-range-thumb {
    .rangeThumb;
  }
  &::-ms-thumb {
    .rangeThumb;
  }

  // Track
  &::-webkit-slider-runnable-track {
    .rangeTrack;
  }
  &::-moz-range-track {
    .rangeTrack;
  }
  &::-ms-track {
    .rangeTrack;
  }
  &:focus { // override outline/background on focus
    background: none;
    outline: none;
  }
  &::-ms-track { // A little somethin' somethin' for IE
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
}

// Labels below slider
.range-labels {
  margin: 16px -41px 62px;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    float: left;
    width: 25%;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
  }

  .selected::before {
    background: #fff;
  }

  .active.selected::before {
    display: none;
  }
}

/* popup */
.alert-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(255, 255, 255, .88);
  .display-flex();
  .align-items();
  .justify-content();
  z-index: 5;
  .alert {
    background-color: #f4f4f4;
    padding: 60px 30px 80px;
    position: relative;
    text-align: center;
    max-width: 668px;
    width: 100%;
    border-bottom: 2px solid @green;
    .box-shadow(3px 6px 10px rgba(0, 0, 0, .14));
    .close {
      background-color: transparent;
      border: 0;
      position: absolute;
      top: 21px;
      right: 20px;
      font-size: 30px;
      cursor: pointer;
      color: #333333;
    }
    img.icon {
      margin: 0 auto 46px;
    }
    p {
      line-height: normal;
    }
  }
}

.popup {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -20px;
  right: -20px;
  margin: auto;
  z-index: 20;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  * {
    color: #fff;
  }

  .popup-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #242b33;
    cursor: pointer;
  }
  .popup-block {
    position: absolute;
    top: 3vh;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 1170px;
    width: 90%;
    background-color: transparent;
    padding: 0;
    .popup-close {
      width: 100%;
      margin: auto;
      max-width: 678px;
      height: 64px;
      cursor: pointer;
      z-index: 1;
      position: relative;
      //left: 20%;

      &:before, &:after {
        content: '';
        height: 2px;
        width: 24px;
        background-color: #fff;
        position: absolute;
        top: 10px;
        right: 0;
        .border-radius(30px);
      }
      &:before {
        .transform(rotate(45deg));
      }
      &:after {
        .transform(rotate(-45deg));
      }
    }
    .popup-content {
      padding: 92px 30px;
      margin: auto;
      .block-title {
        text-align: center;
        text-transform: uppercase;
        font-size: 30px;
        line-height: 33px;
        margin-bottom: 30px;
        font-family: @Roboto;
        font-weight: 400;
      }
      .desc-block {
        &, * {
          text-align: center;
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 80px;
        }
      }
      form {
        .row {
          width: calc(~'100% + 30px');
          width: -webkit-calc(~'100% + 30px');
          width: -moz-calc(~'100% + 30px');
          margin-left: -15px;
          margin-right: -15px;
          [class*='col-'] {
            padding: 0 15px 65px;
          }
        }
        .form-group {
          //width: calc(~'50% - 126px');
          //width: -webkit-calc(~'50% - 126px');
          //width: -moz-calc(~'50% - 126px');
          position: relative;
          label {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 14px;
            opacity: .3;
            .transition(all .2s);
          }
          input:not([type=submit]), textarea {
            width: 100%;
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid @wildSand;
            height: 42px;
            .transition(all .2s);
            font-size: 18px;
            font-family: @OpenSans;
            resize: none;
          }
          input:focus, textarea:focus {
            border-bottom: 2px solid @wildSand;
            border-bottom: 2px solid @wildSand;
            & + label {
              top: -18px;
              font-size: 12px;
            }
          }
          &.focused {
            input {
              border-bottom: 2px solid @wildSand;
            }
            label {
              top: -18px;
              font-size: 12px;
            }
          }
        }
        .submit-group {
          text-align: center;
          margin-top: 15px;
          .btn {
            margin: auto;
          }
        }
      }
    }
  }
  &#choose-transport-popup2 {
    * {
      color: #34495e;
    }
    .popup-bg {
      background-color: rgba(255, 255, 255, 0.92);
    }
    .popup-content {
      background-color: #f4f4f4;
      padding-bottom: 27px;
      .box-shadow(0 4px 5px 0 rgba(0, 0, 0, .11));
      .popup-close {
        position: absolute;
        color: #34495e;
        right: 30px;
        top: 30px;
        max-width: 100%;
        &:after, &:before {
          background-color: #34495e;
          width: 16px;
        }
      }
      form {
        .form-group {
          input:not([type=submit]), textarea {
            border-bottom-color: #34495e;
          }
        }
      }
    }

  }
}

.clearfix {
  display: block;
  clear: both;
}

/* mobile */
@media all and (max-width: 1600px) {
  main .rent-section {
    background-position: 36vw 100%;
    .rent-block .rent-content .rent-item {
      background-position: 24vw 0;
    }
  }
}

@media all and (max-width: 1199px) {
  main .rent-section {
    background-position: 36vw 100%;
    .rent-block .rent-content .rent-item {
      background-position: 28vw 0;
    }
  }
}

@media all and (max-width: 991px) and (orientation: landscape) {
  .popup {
    .popup-block {
      top: 0;
      .popup-content {
        padding: 25px;
        form .row [class*='col-'] {
          padding-bottom: 35px;
        }
        .desc-block {
          &, * {
            margin-bottom: 50px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 991px) {
  main {
    section {
      padding: 42px 0;
    }
    .desc-block {
      width: 100% !important;
    }
    .autopark-section {
      .autopark-block {
        .autopark-list li a {
          padding: 12px;
        }
      }
      .autopark-content, .autopark-grid {
        .autopark-item {
          .row {
            .flex-direction(column, normal, vertical);
            & > div {
              width: 100%;
              &.price-block {
                padding: 0 15px;
              }
            }
          }
        }
      }
    }
    .calc-section, .advantages-section, .reviews-section, .blog-section {
      [class*='col-'] {
        width: 100%;
      }
    }
    .order-section {
      .order-form {
        max-width: 354px;
        margin-left: auto;
        margin-right: auto;
      }
      .form-group, .submit-group {
        width: 100%;
      }
    }
    .transfer-section {
      .transfer-list ul li {
        a {
          font-size: 14px;
        }
        span, img, svg {
          height: 40px;
        }
      }
    }
    .blog-page-section {
      .blog-grid {
        & > [class*='col-'] {
          width: 100%;
        }
        .sidebar {
          margin-top: 30px;
          padding: 32px 5.6vw;
        }
      }
    }
    .rent-section {
      .rent-block .rent-content .rent-item {
        background-image: none !important;
      }
    }
  }
}

@media all and (max-width: 767px) {
  header {
    .phones-block {
      & > div {
        margin-top: 7px;
        margin-bottom: -14px;
      }
      .fa-phone {
        width: 40px;
        height: 40px;
        //border: none;
        //bottom: 0;
        //margin-right: 0;
        //&:before {
        //  font-size: 13px;
        //}
      }
      a {
        font-size: 13px;
      }
    }
    .order-block {
      display: none;
    }
    .logo-block {
      width: calc(~'100% - 100px');
      width: -webkit-calc(~'100% - 100px');
      width: -moz-calc(~'100% - 100px');
      padding-bottom: 10px;
      img {
        max-width: 80px;
      }
    }
    .menu-block {
      width: 100px;
    }
  }

  section, footer, .popup {
    [class*='col-'] {
      width: 100%;
    }
  }

  main {
    section {
      .desc-block {
        ul {
          &.columns-2 {
            .column-count(1);
          }
        }
      }
    }
    .rent-section .rent-block {
      .rent-list {
        width: 26%;
        li {
          span {
            margin: auto;
          }
          svg {
            margin: auto !important;
            display: block;
          }
          &, &.active, &:hover {
            a {
              font-size: 0;
              text-indent: -9999px;
            }
          }
        }
      }
      .rent-content .rent-item {
        .block-title {
          font-size: 23px;
          margin-bottom: 64px;
        }
        p {
          margin-bottom: 46px;
        }
      }
    }

    .reviews-section .reviews-block, .blog-section .blog-grid .blog-item .content-block .desc-block {
      padding-left: 25px;
      padding-right: 25px;
    }
    .blog-section .blog-grid .blog-item .content-block {
      width: calc(~'100% - 40px');
      width: -webkit-calc(~'100% - 40px');
      width: -moz-calc(~'100% - 40px');
    }
    .transfer-section {
      .transfer-list ul {
        .flex-wrap();
        li {
          width: 33.33333%;
        }
      }
    }
    .blog-page-section {
      .blog-grid .main-content .blog-list .blog-item {
        .flex-direction(column, normal, vertical);
        .img-block {
          width: 100%;
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
  }

  .error-section {
    .site-error {
      text-align: center;
    }
    .img-block {
      margin: auto;
    }
  }

  footer .footer-top {
    .copyright {
      margin-bottom: 45px;
    }
    .footer-contacts-block, .services-menu {
      margin-bottom: 25px;
    }
    .footer-menu {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  .popup {
    .popup-block {
      top: 0;
      .popup-content {
        padding: 25px;
        form .row [class*=col-] {
          padding-bottom: 35px;
        }
        .desc-block {
          &, * {
            margin-bottom: 50px;
          }
        }
      }
    }
  }
}