/* reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 14px;
	font-family: @HelveticaNeue;
	vertical-align: baseline;
	color:       #1d1d1b;
	line-height: normal;
	.box-sizing(border-box);
	//position: relative;
	.appearance();
}
img {max-width: 100%;}
article, aside, details, figcaption, figure, footer, header, menu, nav, section {
	display: block;
}
html {
	min-height: 100%;
	position: relative;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}

a {
	text-decoration: none;
	outline: none!important;
	&:hover {text-decoration: underline; outline: none!important; }
}

a:focus, button:focus, a:active {
	outline: none!important;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.center {text-align: center;}
.leftalign {text-align: left;}
.rightalign {text-align: right!important;}
.leftfloat {float: left;}
.rightfloat {float: right;}
strong {font-weight: bold;}
input, select, textarea {
	.appearance();
	.box-sizing(border-box);
	&:focus {
		outline: none;
	}
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

.container, .container-fluid {
	width: 100%;
	margin-left: auto; margin-right: auto;
	z-index: 1;
}
.container {max-width: 1210px; padding-left: 20px; padding-right: 20px;}


.row {
	.display-flex();
	.flex-direction();
	.flex-wrap(@flex-wrap: wrap);
	width: 100%;
}

.calcCol(@num: 1) {
	width: 100% / 12 * @num;
}

.col-1 {
	.calcCol()
}

.col-2 {
	.calcCol(@num: 2)
}

.col-3 {
	.calcCol(@num: 3)
}

.col-4 {
	.calcCol(@num: 4)
}

.col-5 {
	.calcCol(@num: 5)
}

.col-6 {
	.calcCol(@num: 6)
}

.col-7 {
	.calcCol(@num: 7)
}

.col-8 {
	.calcCol(@num: 8)
}

.col-9 {
	.calcCol(@num: 9)
}

.col-10 {
	.calcCol(@num: 10)
}

.col-12 {
	.calcCol(@num: 12)
}

.col-20 {
	width: 20%;
}

.col-80 {
	width: 80%;
}


[class^='col-'] {
	.display-flex();
	.flex-direction(@flex-direction: column, @box-direction: normal, @box-orient: vertical);
}
