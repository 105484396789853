@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@font-face {
  font-family: 'HelveticaNeueCyr-Roman';
  src: url('fonts/HelveticaNeueCyr-Roman.eot');
  src: url('fonts/HelveticaNeueCyr-Roman.woff2') format('woff2'),
  url('fonts/HelveticaNeueCyr-Roman.woff') format('woff'),
  url('fonts/HelveticaNeueCyr-Roman.ttf') format('truetype'),
  url('fonts/HelveticaNeueCyr-Roman.svg#HelveticaNeueCyr-Roman') format('svg'),
  url('fonts/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr-Bold';
  src: url('fonts/HelveticaNeueCyr-Bold.eot');
  src: url('fonts/HelveticaNeueCyr-Bold.woff2') format('woff2'),
  url('fonts/HelveticaNeueCyr-Bold.woff') format('woff'),
  url('fonts/HelveticaNeueCyr-Bold.ttf') format('truetype'),
  url('fonts/HelveticaNeueCyr-Bold.svg#HelveticaNeueCyr-Bold') format('svg'),
  url('fonts/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}


@HelveticaNeue: 'HelveticaNeueCyr-Roman', sans-serif;
@HelveticaNeueBold: 'HelveticaNeueCyr-Bold', sans-serif;
@Roboto: 'Roboto', sans-serif;
@OpenSans: 'Open Sans', sans-serif;

@green: #00bc65;
@wildSand: #f4f4f4;


